<template>
  <div :class="classRoot">
    <div class="card" :class="{ 'card-black': black }" >
      <div class="card-body no-padding no-margim">
        <div class="row">
          <div class="col col-8"  :class="{ 'col-12': !displayGoal || !showChart }">
            <div class="row">
              <div class="card-title" v-if="$slots.title">
                <slot name="title"></slot>
              </div>
            </div>
            <div class="row">
              <div class="col col-6 mt-3" :class="{ 'col-12': !displayGoal }">
                <div  v-if="abrev" class="value">
                  {{ prefix}} {{ $util.formatNumberAbrev(realizado, decimals, ',', '.', 0) }} <br/>
                </div>
                <div v-else class="value">
                  {{ prefix}} {{ $util.formatNumber(realizado, decimals, ',', '.', 0) }} <br/>
                </div>
                <div class="small">{{ labelRealizado }}</div>
              </div>
              <div class="col col-6 mt-3" v-show="displayGoal">
                <div  v-if="abrev" class="value">
                  {{ prefix}} {{ $util.formatNumberAbrev(meta, decimals, ',', '.', 0) }} <br/>
                </div>
                <div v-else class="value">
                  {{ prefix}} {{ $util.formatNumber(meta, decimals, ',', '.', 0) }} <br/>
                </div>
                <div class="small">{{ labelMeta }}</div>
              </div>
            </div>
          </div>
          <div class="col col-4 pl-2 pt-2" v-show="displayGoal">
            <slot name="graphic"></slot>
          </div>
<!--          <div class="col col-4">-->
<!--            <div class="grafico">-->
<!--             Gráfico-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="card-legend" v-if="$slots.legend">
        <slot name="legend"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardGoal',
  components: {
  },
  props: {
    showChart: {
      type: Boolean,
      required: false,
      default: true
    },
    abrev: {
      type: Boolean,
      required: false,
      default: true
    },
    decimals: {
      type: Number,
      required: false,
      default: 1
    },
    prefix: {
      type: String,
      required: false,
      default: ''
    },
    hideGoal: {
      type: Boolean,
      required: false,
      default: false
    },
    labelRealizado: {
      type: String,
      required: false,
      default: 'Embarcados'
    },
    labelMeta: {
      type: String,
      required: false,
      default: 'Meta'
    },
    realizado: {
      type: Number,
      required: true,
      default: 0
    },
    meta: {
      type: Number,
      required: true,
      default: 0
    },
    black: {
      type: Boolean,
      default: false,
      required: false
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    classRoot: {
      type: [String, Object, Array],
      default: 'card-fullheight'
    }
  },
  computed: {
    displayGoal () {
      let display = true

      if (this.hideGoal) {
        if (this.meta === 0) {
          display = false
        }
      }

      return display
    },
    calculatePercent () {
      if (this.meta <= 0) {
        if (this.realizado > 0) {
          return 100
        } else {
          return 0
        }
      } else {
        return this.realizado * 100.0 / this.meta
      }
    }
  }
}
</script>

<style scoped>
  .card-black {
    background-color: #3D3D3D;
  }

  .card {
    margin: 0px;
    padding: 16px 24px;
  }

  .card-title, .card-title b {
    margin: 0;
    padding: 0;
    text-align: left;
  }
  .no-padding, .no-padding .row {
    padding: 0;
  }
  .no-margim, .no-margim .row {
    margin: 0;
  }

  .no-padding .col{
    padding: 0;
  }

  .card-black .card-title {
    color: #B1B1B1;
  }
  .card-black .card-body {
    color: #fefefe;
  }

  .grafico {
    width: 70px;
    height: 70px;
    background-color: lightgrey;
    font-size: 9px;
    text-align: center;
  }

  .value {
    font-size: 20px;
  }
</style>
